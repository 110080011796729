import request from "@/utils/request";

// 检验报告
export function inspectionReportList(params) {
  return request({ url: `/inspection_reports/`, method: "get", params });
}

export function inspectionReportRetrieve(params) {
  return request({ url: `/inspection_reports/${params.id}/`, method: "get", params });
}

export function inspectionReportNumber(params) {
  return request({ url: `/inspection_reports/number/`, method: "get", params });
}

export function inspectionReportCreate(data) {
  return request({ url: `/inspection_reports/`, method: "post", data });
}

export function inspectionReportUpdate(data) {
  return request({ url: `/inspection_reports/${data.id}/`, method: "put", data });
}

export function inspectionReportDestroy(data) {
  return request({ url: `/inspection_reports/${data.id}/`, method: "delete", data });
}

export function inspectionReportCommit(data) {
  return request({ url: `/inspection_reports/${data.id}/commit/`, method: "post", data });
}

export function inspectionReportSubmit(data) {
  return request({ url: `/inspection_reports/${data.id}/submit/`, method: "post", data });
}

export function inspectionReportVoid(data) {
  return request({ url: `/inspection_reports/${data.id}/void/`, method: "post", data });
}

export function inspectionFileUpload(data) {
  return request({
    url: "/inspection_files/",
    method: "post",
    data,
  });
}

// 工序检验报告
export function processInspectionReportList(params) {
  return request({ url: `/process_inspection_reports/`, method: "get", params });
}

export function processInspectionReportRetrieve(params) {
  return request({ url: `/process_inspection_reports/${params.id}/`, method: "get", params });
}

export function processInspectionReportNumber(params) {
  return request({ url: `/process_inspection_reports/number/`, method: "get", params });
}

export function processInspectionReportCreate(data) {
  return request({ url: `/process_inspection_reports/`, method: "post", data });
}

export function processInspectionReportUpdate(data) {
  return request({ url: `/process_inspection_reports/${data.id}/`, method: "put", data });
}

export function processInspectionReportDestroy(data) {
  return request({ url: `/process_inspection_reports/${data.id}/`, method: "delete", data });
}

export function processInspectionFileUpload(data) {
  return request({
    url: "/process_inspection_files/",
    method: "post",
    data,
  });
}

export function inspectionPointList(params) {
  return request({ url: `/inspection_points/`, method: "get", params });
}

export function inspectionPointCreate(data) {
  return request({ url: `/inspection_points/`, method: "post", data });
}

export function inspectionPointUpdate(data) {
  return request({ url: `/inspection_points/${data.id}/`, method: "put", data });
}

export function inspectionPointDestroy(data) {
  return request({ url: `/inspection_points/${data.id}/`, method: "delete", data });
}

export function inspectionStandardList(params) {
  return request({ url: `/inspection_standards/`, method: "get", params });
}

export function inspectionStandardCreate(data) {
  return request({ url: `/inspection_standards/`, method: "post", data });
}

export function inspectionStandardUpdate(data) {
  return request({ url: `/inspection_standards/${data.id}/`, method: "put", data });
}

export function inspectionStandardDestroy(data) {
  return request({ url: `/inspection_standards/${data.id}/`, method: "delete", data });
}

export function processInspectionTaskList(params) {
  return request({ url: `/process_inspection_tasks/`, method: "get", params });
}

export function processInspectionTaskRetrieve(params) {
  return request({ url: `/process_inspection_tasks/${params.id}/`, method: "get", params });
}

export function processInspectionTaskPass(data) {
  return request({ url: `/process_inspection_tasks/${data.id}/mark_pass/`, method: "post", data });
}

export function processInspectionTaskFail(data) {
  return request({ url: `/process_inspection_tasks/${data.id}/mark_fail/`, method: "post", data });
}
